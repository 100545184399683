/* eslint-disable */
import { ApiMethodParameter, ApiMethodData } from "../ApiMethod";
import { ApiObjectProperty, ApiObjectData } from "../ApiObject";

//ToDo: Parser needs to be refactored to reduce complexity
interface MyAParameterInfo extends ApiMethodParameter {
    isRequired: boolean;
    isSupported: boolean;
}

interface MyAPropertyInfo extends ApiObjectProperty {
    isSupported: boolean;
}

export interface MyAMethodInfo extends ApiMethodData {
    parameters: MyAParameterInfo[];
    roles: string[];
    isSupported: boolean;
    remarks: string;
}

export interface MyAObjectInfo extends ApiObjectData {
    properties: MyAPropertyInfo[];
    isSupported: boolean;
    hasValues: boolean;
}

export interface MyAParserOutput {
    [name: string]: MyAMethodInfo | MyAObjectInfo;
}

function extractSubstrings(input: string): string {
    const webMethodsMatch = input.match(/MyAdminApiService\.([a-zA-Z]+)/);
    const dataStoreMatch = input.match(/DataStore\.([a-zA-Z]+)/);
    if (webMethodsMatch) {
        return webMethodsMatch[1];
    } else if (dataStoreMatch) {
        return dataStoreMatch[1];
    } else {
        return "";
    }
}

function isMethod(namespace: string): boolean {
    return namespace.includes("M:Geotab.Internal.MyAdmin") || namespace.includes("M:Geotab.Checkmate.ObjectModel");
}

function isObject(namespace: string): boolean {
    return namespace.includes("T:");
}

function isObjectProperty(namespace: string): boolean {
    return namespace.includes("P:") || namespace.includes("F:");
}

function isMyAObject(parameterName: string | null): boolean {
    if (parameterName && typeof window !== "undefined") {
        let storageItem = parameterName.charAt(0).toUpperCase() + parameterName.slice(1) + "MYA";
        return JSON.parse(sessionStorage.getItem(storageItem)!)?.hasOwnProperty("properties");
    } else {
        return false;
    }
}

function isMyAId(parameterName: string | null): boolean {
    return parameterName === "Id";
}

function getValueSeeCref(item: Element): string {
    let valueElements = item.getElementsByTagName("value");
    for (let i = 0; i < valueElements.length; i++) {
        let seeElements = valueElements[i].getElementsByTagName("see");
        for (let j = 0; j < seeElements.length; j++) {
            return seeElements[j].attributes.getNamedItem("cref")?.nodeValue || "";
        }
    }
    return "";
}

function getDataTypeFromNamespace(namespace: string): string {
    let namespaceArray: string[] = namespace.split(".");
    return namespaceArray[namespaceArray.length - 1];
}

function parseMethodInfo(item: Element, namespace: string): MyAMethodInfo {
    let method: MyAMethodInfo = {
        description: "",
        parameters: [],
        roles: [],
        returns: "",
        example: "",
        isSupported: false,
        remarks: ""
    };
    let dataTypeArray: string[] = getMethodParameterDataTypeFromNamespace(namespace);
    for (let k = 0; k < item.childNodes.length; k++) {
        if (item.childNodes[k].nodeName === "isSupported") {
            if ((item.childNodes[k] as Element).innerHTML === "true") {
                method.isSupported = true;
            }
        }
        if (item.childNodes[k].nodeName === "roles") {
            let rolesText = item.childNodes[k].textContent;
            if (rolesText) {
                method.roles = rolesText.split(",").map((role) => role.trim());
            }
        }
        if (item.childNodes[k].nodeName === "summary") {
            if (item.childNodes[k].hasChildNodes()) {
                let summaryChildren = item.childNodes[k];
                let summaryText: string = "";

                for (let l = 0; l < summaryChildren.childNodes.length; l++) {
                    if (summaryChildren.childNodes[l].nodeName === "#text") {
                        summaryText += summaryChildren.childNodes[l].nodeValue?.replace(/\s+/g, " ");
                    }
                    if (summaryChildren.childNodes[l].nodeName === "see") {
                        summaryText += (summaryChildren.childNodes[l] as Element).outerHTML;
                    }
                    if (summaryChildren.childNodes[l].nodeName === "list") {
                        let listItems = summaryChildren.childNodes[l];
                        for (let m = 0; m < listItems.childNodes.length; m++) {
                            summaryText += "\n- " + (listItems.childNodes[m].childNodes[0] as Element).innerHTML;
                        }
                    }
                }
                method.description = summaryText.trimStart();
            }
        }
        if (item.childNodes[k].nodeName === "remarks") {
            if (item.childNodes[k].hasChildNodes()) {
                let remarksChildren = item.childNodes[k];
                let remarksText: string = "";

                for (let l = 0; l < remarksChildren.childNodes.length; l++) {
                    if (remarksChildren.childNodes[l].nodeName === "#text") {
                        remarksText += remarksChildren.childNodes[l].nodeValue?.replace(/\s+/g, " ");
                    }
                }

                method.remarks = remarksText.trimStart();
            }
        }
        if (item.childNodes[k].nodeName === "param") {
            if ((item.childNodes[k] as Element).getAttribute("name") === "cancellationToken") {
                dataTypeArray.shift()!;
            } else {
                let methodName = (item.childNodes[k] as Element).attributes.getNamedItem("name")?.nodeValue ?? "";

                let descriptionText: string = "";
                for (let l = 0; l < item.childNodes[k].childNodes.length; l++) {
                    if (item.childNodes[k].childNodes[l].nodeName === "#text") {
                        descriptionText += item.childNodes[k].childNodes[l].nodeValue?.replace(/\s+/g, " ");
                    }
                    if (item.childNodes[k].childNodes[l].nodeName === "see") {
                        descriptionText += (item.childNodes[k].childNodes[l] as Element).outerHTML;
                    }
                }

                let dataType =
                    dataTypeArray.length > 0
                        ? dataTypeArray.shift()!
                        : isMyAObject(methodName)
                          ? "Object"
                          : isMyAId(methodName)
                            ? "String"
                            : getValueSeeCref(item) && isMyAObject(getDataTypeFromNamespace(getValueSeeCref(item)))
                              ? "Object"
                              : getValueSeeCref(item)
                                ? getDataTypeFromNamespace(getValueSeeCref(item)) // get namespace's datatype
                                : "";
                let paramDict: MyAParameterInfo = {
                    name: methodName,
                    description: descriptionText,
                    isRequired: (item.childNodes[k] as Element).attributes.hasOwnProperty("required"),
                    isSupported: true,
                    dataType: dataType
                };
                method.parameters.push(paramDict);
            }
        }

        if (item.childNodes[k].nodeName === "returns") {
            let returnText: string = "";
            for (let l = 0; l < item.childNodes[k].childNodes.length; l++) {
                if (item.childNodes[k].childNodes[l].nodeName === "#text") {
                    returnText += item.childNodes[k].childNodes[l].nodeValue?.replace(/\s+/g, " ");
                }
                if (item.childNodes[k].childNodes[l].nodeName === "see") {
                    returnText += (item.childNodes[k].childNodes[l] as Element).outerHTML;
                }
            }
            method.returns = returnText.trimStart();
        }
    }
    return method;
}

function parseObjectInfo(item: Element): MyAObjectInfo {
    let object: MyAObjectInfo = {
        description: "",
        properties: [],
        isSupported: false,
        hasValues: false
    };
    for (let k = 0; k < item.childNodes.length; k++) {
        if (item.childNodes[k].nodeName === "isSupported") {
            if ((item.childNodes[k] as Element).innerHTML === "true") {
                object.isSupported = true;
            }
        }
        if (item.childNodes[k].nodeName === "summary") {
            if (item.childNodes[k].hasChildNodes()) {
                let summaryChildren = item.childNodes[k].childNodes;
                let summaryText: string = "";
                for (let l = 0; l < summaryChildren.length; l++) {
                    if (summaryChildren[l].nodeName === "isSupported") {
                        if ((summaryChildren[l] as Element).innerHTML === "true") {
                            object.isSupported = true;
                        }
                    }
                    if (summaryChildren[l].nodeName === "text" || summaryChildren[l].nodeName === "#text") {
                        summaryText += summaryChildren[l].nodeValue?.replace(/\s+/g, " ");
                    }
                    if (summaryChildren[l].nodeName === "see" || summaryChildren[l].nodeName === "a") {
                        summaryText += (summaryChildren[l] as Element).outerHTML;
                    }
                    if (summaryChildren[l].nodeName === "seealso") {
                        let crefAttribute = (summaryChildren[l] as Element).attributes.getNamedItem("cref")?.nodeValue || "";
                        let propertyNameFromCref = getDataTypeFromNamespace(crefAttribute);
                        summaryText += propertyNameFromCref;
                    }
                    if (summaryChildren[l].nodeName === "para") {
                        for (let m = 0; m < summaryChildren[l].childNodes.length; m++) {
                            if (summaryChildren[l].childNodes[m].nodeName === "#text") {
                                summaryText += summaryChildren[l].childNodes[m].nodeValue?.replace(/\s+/g, " ");
                            }
                            if (summaryChildren[l].childNodes[m].nodeName === "see") {
                                summaryText += (summaryChildren[l].childNodes[m] as Element).outerHTML;
                            }
                        }
                        if (l !== summaryChildren.length - 1) {
                            summaryText += "\n";
                        }
                    }
                    if (summaryChildren[l].nodeName === "list") {
                        let listItems = summaryChildren[l].childNodes;
                        for (let m = 0; m < listItems.length; m++) {
                            for (let n = 0; n < listItems[m].childNodes.length; n++) {
                                if (listItems[m].childNodes[n].childNodes[0].nodeName === "see") {
                                    summaryText += "\n- " + (listItems[m].childNodes[n].childNodes[0] as Element).outerHTML;
                                } else {
                                    summaryText += "\n- " + listItems[m].childNodes[n].childNodes[0].nodeValue?.replace(/\s+/g, " ");
                                }
                            }
                        }
                    }
                }
                object.description = summaryText.trimStart();
            }
        }
    }
    return object;
}

function parseObjectPropertiesInfo(item: Element, propertyName: string): MyAPropertyInfo {
    let objectProperty: MyAPropertyInfo = {
        name: propertyName,
        description: "",
        isBeta: false,
        isSupported: true,
        dataType: ""
    };

    let valueNamespace = getValueSeeCref(item);
    let namespaceDataType = getDataTypeFromNamespace(valueNamespace);
    let dataType = valueNamespace && isMyAId(namespaceDataType) ? "String" : namespaceDataType;
    objectProperty.dataType = dataType;
    // NOTE: will check to see if property is my a object after dictionary is built (see transformJSON function)

    let descriptionText = "";

    for (let k = 0; k < item.childNodes.length; k++) {
        if (item.childNodes[k].nodeName === "summary") {
            for (let l = 0; l < item.childNodes[k].childNodes.length; l++) {
                if (item.childNodes[k].childNodes[l].nodeName === "#text") {
                    descriptionText += item.childNodes[k].childNodes[l].nodeValue?.replace(/\s+/g, " ");
                }
                if (item.childNodes[k].childNodes[l].nodeName === "see") {
                    let text = (item.childNodes[k].childNodes[l] as Element).outerHTML;
                    const regex: RegExp = /<see cref="(.*?)">(.*?)<\/see>/g;
                    let result = text;
                    if (regex.test(text)) {
                        result = text.replace(regex, '<see cref="$1"/>');
                    }

                    descriptionText += result;
                }
                if (item.childNodes[k].childNodes[l].nodeName === "list") {
                    let properyListItems = item.childNodes[k].childNodes[l].childNodes;
                    for (let m = 0; m < properyListItems.length; m++) {
                        for (let n = 0; n < properyListItems[m].childNodes.length; n++) {
                            if (properyListItems[m].childNodes[n].childNodes[0].nodeName === "see") {
                                descriptionText += "\n- " + (properyListItems[m].childNodes[n].childNodes[0] as Element).outerHTML;
                            } else {
                                descriptionText += "\n- " + properyListItems[m].childNodes[n].childNodes[0].nodeValue?.replace(/\s+/g, " ");
                            }
                        }
                    }
                }
            }
        }
    }
    objectProperty.description = descriptionText.trimStart();
    return objectProperty;
}

// Extracts and processes parameter data types from a namespace string, filtering out empty parameters and specific values, and converting long data type names to short ones.
// Example input: M:Geotab.Internal.MyAdmin.Api.Handlers.Legacy.MyAdminApiService.Authenticate(System.String,System.String)
// Example output: ['String', 'String']
function getMethodParameterDataTypeFromNamespace(namespace: string): string[] {
    let openingParenthesisIndex = namespace.indexOf("(");
    let parametersString = openingParenthesisIndex > -1 ? namespace.substring(openingParenthesisIndex + 1, namespace.length - 1) : "";
    let parameterArray = parametersString
        .split(",")
        .filter((item) => item.trim() !== "``0" && item.trim() !== "")
        .map((item) => {
            if (item.includes("Collections")) {
                return "List";
            }
            let shortDataType = convertLongToShort(item);
            return isMyAObject(shortDataType) ? (shortDataType = "Object") : shortDataType;
        });
    return parameterArray;
}

function convertLongToShort(longType: string): string {
    /* eslint-disable max-len */
    const regex =
        /(?:Geotab\.|Microsoft\.AspNetCore\.Http\.)?(?:Checkmate\.ObjectModel\.)?(?:[A-Za-z]+\.)*Nullable\{\s*System\.\s*([A-Za-z0-9]+)\s*\}|(?:Geotab\.|Microsoft\.AspNetCore\.Http\.)?(?:Checkmate\.ObjectModel\.)?(?:[A-Za-z]+\.)*([A-Za-z0-9]+)/g;
    const match = regex.exec(longType);
    if (match) {
        if (match[1]) {
            return match[1]; // For Nullable case
        } else if (match[2]) {
            return match[2]; // For regular case
        }
    }
    return longType;
}

function transformJSON(json: { [key: string]: MyAMethodInfo | MyAObjectInfo }) {
    Object.keys(json).forEach((key) => {
        let value = json[key];
        if ((value as MyAMethodInfo).parameters) {
            for (let i = 0; i < (json[key] as MyAMethodInfo).parameters.length; i++) {
                let prop = (json[key] as MyAMethodInfo).parameters[i];
                if (json[prop.dataType] as MyAObjectInfo) {
                    (json[key] as MyAMethodInfo).parameters[i].dataType = "Object";
                }
            }
        }
        if ((value as MyAObjectInfo).properties) {
            for (let i = 0; i < (json[key] as MyAObjectInfo).properties.length; i++) {
                let prop = (json[key] as MyAObjectInfo).properties[i];
                if (json[prop.dataType] as MyAObjectInfo) {
                    (json[key] as MyAObjectInfo).properties[i].dataType = "Object";
                }
            }
        }
    });
}

function sortJSON(json: { [key: string]: MyAMethodInfo | MyAObjectInfo }) {
    Object.keys(json).forEach((key) => {
        let value = json[key];
        if ((value as MyAMethodInfo).parameters) {
            (value as MyAMethodInfo).parameters = (value as MyAMethodInfo).parameters.sort((a, b) => a.name.localeCompare(b.name));
        } else if ((value as MyAObjectInfo).properties) {
            (json[key] as MyAObjectInfo).properties = (json[key] as MyAObjectInfo).properties.sort((a, b) => a.name.localeCompare(b.name));
        }
    });
}

export default function myAParser(xml: XMLDocument, itemType: string): MyAParserOutput {
    let json: { [key: string]: MyAMethodInfo | MyAObjectInfo } = {};
    if (xml.hasChildNodes()) {
        if (xml.childNodes[0].nodeName === "doc") {
            let item: NodeListOf<ChildNode> = xml.childNodes[0].childNodes;
            for (let i = 0; i < item.length; i++) {
                if (item[i].nodeName === "members") {
                    for (let j = 0; j < item[i].childNodes.length; j++) {
                        if (item[i].childNodes[j].nodeName === "member") {
                            let node = item[i].childNodes[j] as Element;
                            let namespace: string = node.attributes.getNamedItem("name")?.nodeValue ?? "";
                            if (itemType === "method" && isMethod(namespace)) {
                                let methodName: string = extractSubstrings(namespace);
                                let methodInfo = parseMethodInfo(node, namespace);
                                if (!json[methodName] && methodInfo.isSupported) {
                                    json[methodName] = methodInfo;
                                }
                            } else if (itemType === "object" && isObject(namespace)) {
                                let namespaceArray: string[] = namespace.split(".");
                                let objectName: string = namespaceArray[namespaceArray.length - 1].replace(/[^a-zA-Z0-9]/g, "");
                                let objectInfo = parseObjectInfo(node);
                                if (!json[objectName] && objectInfo.isSupported && objectName !== "MyAdminApiService") {
                                    json[objectName] = objectInfo;
                                }
                            } else if (itemType === "object" && isObjectProperty(namespace)) {
                                let namespaceArray: string[] = namespace.split(".");
                                let objectName: string = namespaceArray[namespaceArray.length - 2].replace(/[^a-zA-Z0-9]/g, "");
                                let objectPropertyName: string = namespaceArray[namespaceArray.length - 1].replace(/[^a-zA-Z0-9]/g, "");
                                let objectInfo = parseObjectPropertiesInfo(node, objectPropertyName);
                                (json[objectName] as MyAObjectInfo) && (json[objectName] as MyAObjectInfo).properties.push(objectInfo);
                            }
                        }
                    }
                }
            }
            transformJSON(json);
            sortJSON(json);
        }
    }
    return json;
}
