import React, { useRef, useEffect, useCallback, useState, ComponentType, ReactNode } from "react";
import SearchResultTabContent from "./SearchResultTabContent";
import { searchIndex } from "../../utils/searchIndex.js";
import MiniSearch, { SearchResult } from "minisearch";
import { SearchSections } from "./searchSectionsEnum";
import { searchAndFilter } from "./utils/minisearchUtils";
import FocusTrap from "focus-trap-react";
import "./searchModal.scss";
import { IconClipboard } from "@dev/zenith/dist/icons/iconClipboard";
import { IconServer } from "@dev/zenith/dist/icons/iconServer";
import { IconSearch } from "@dev/zenith/dist/icons/iconSearch";
import { IconClose } from "@dev/zenith/dist/icons/iconClose";
import { TabBar } from "@dev/zenith";
import { IIcon } from "@dev/zenith/dist/icons/icon";
import { SearchInput } from "@dev/zenith/dist/searchInput/searchInput";

// ToDo: Once dev gets back to us about why the spans need to be widened and the ITabBarElement interface export we will
// need to update this file accordingly.

interface SearchModalProps {
    isOpen: boolean;
    onClose: () => void;
}

interface ITabBarElement {
    name: string;
    icon?: React.ReactElement;
    content: ReactNode;
    id: string;
}

const SEARCH_RESULTS_LIMIT = 50;

const resultCategories: Record<string, ComponentType<IIcon>> = {
    [SearchSections.All]: IconSearch,
    [SearchSections.APIReference]: IconServer,
    [SearchSections.Guides]: IconClipboard
};

export let miniSearch: MiniSearch = new MiniSearch({
    fields: ["title", "content", "headers"], // fields to index for full-text search
    storeFields: ["title", "category", "breadCrumb", "link"], // fields to return with search results
    searchOptions: {
        fuzzy: 0.2,
        prefix: true,
        boost: {
            title: 3,
            headers: 2
        }
    }
});

miniSearch.addAll(searchIndex);

export default function SearchModal({ isOpen, onClose }: SearchModalProps): JSX.Element | null {
    const modalRef: React.MutableRefObject<HTMLDivElement | null> = useRef<HTMLDivElement | null>(null);
    const inputRef: React.MutableRefObject<HTMLInputElement | null> = useRef<HTMLInputElement | null>(null);
    const [inputValue, setInputValue] = useState<string>("");
    const [searchResults, setSearchResults] = useState<SearchResult[]>([]);
    const defaultTabName: string = Object.keys(resultCategories)[0];
    const defaultTabId: string = `search-tab-${defaultTabName}`;
    const [activeTabId, setActiveTabId] = useState<string>(defaultTabId);

    const handleOutsideClick = useCallback(
        (event: MouseEvent): void => {
            if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
                setInputValue("");
                onClose();
            }
        },
        [onClose]
    );

    const closeModal = useCallback(() => {
        setInputValue("");
        onClose();
    }, [onClose]);

    const handleKeyDown = useCallback(
        (event: KeyboardEvent): void => {
            if (event.key === "Escape") {
                setInputValue("");
                onClose();
            }
        },
        [onClose]
    );

    //debounce for search bar
    useEffect(() => {
        const getSearchResults = setTimeout(() => {
            //Uncomment below for Algolia + backup minisearch
            /*
            algoliaSearch(inputValue).then(hits => {
                setSearchResults(hits);
            }).catch(error => {
                console.error("Problem with Algolia search: ", error);
                let filteredResults: SearchResult[] = searchAndFilter(inputValue);
                setSearchResults(filteredResults.slice(0, SEARCH_RESULTS_LIMIT));
            });
            */
            //Uncomment below for minsearch only
            let filteredResults: SearchResult[] = searchAndFilter(inputValue);
            setSearchResults(filteredResults.slice(0, SEARCH_RESULTS_LIMIT));
        }, 400);
        return () => clearTimeout(getSearchResults);
    }, [inputValue]);

    useEffect(() => {
        if (isOpen) {
            window.addEventListener("keydown", handleKeyDown);
            window.addEventListener("mousedown", handleOutsideClick);
            inputRef.current?.focus();
        } else {
            window.removeEventListener("keydown", handleKeyDown);
            window.removeEventListener("mousedown", handleOutsideClick);
        }

        return () => {
            window.removeEventListener("keydown", handleKeyDown);
            window.removeEventListener("mousedown", handleOutsideClick);
        };
    }, [isOpen, handleOutsideClick, handleKeyDown]);

    const tabs: ITabBarElement[] = Object.keys(resultCategories).map((category: string) => ({
        name: category,
        icon: React.createElement(resultCategories[category], { size: "huger" }),
        content: <SearchResultTabContent inputValue={inputValue} tab={category} searchResults={searchResults} onClose={closeModal} />,
        id: `search-tab-${category}`
    }));

    const handleTabChange = (newActiveTabId: string): void => {
        setActiveTabId(newActiveTabId);
    };

    if (!isOpen) {
        return null;
    }

    return (
        <div className={`search-modal-backdrop`} role="button" aria-labelledby="search-modal">
            <FocusTrap active={isOpen}>
                <div className="search-modal-container" ref={modalRef}>
                    <div className="search-modal-header-container">
                        <SearchInput value={inputValue} onChange={setInputValue} placeholder="Search" ref={inputRef} />
                        <button className="search-modal-icon-close-container" onClick={closeModal}>
                            <IconClose size="huger" />
                        </button>
                    </div>
                    <div className="search-modal-tabs-container">
                        <TabBar tabs={tabs} activeTabId={activeTabId} onTabChange={handleTabChange} />
                    </div>
                </div>
            </FocusTrap>
        </div>
    );
}
