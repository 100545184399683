import { HashLink } from "react-router-hash-link";
import { HeaderSections } from "../../Header/headerSectionsEnum";
import BetaPill from "../../BetaPill/BetaPill";

export default function renderStringWithUrl(name: string, text: string, headerSection: HeaderSections): JSX.Element {
    const linkBase: string = headerSection === HeaderSections.MyGeotab ? "/myGeotab/apiReference/objects" : "/myAdmin/apiReference/objects";
    let unescapedText: string = unescapeText(text);
    let decodedText: string = decodeText(unescapedText);
    let formattedText = replaceBreakTags(decodedText);
    const lines: string[] = splitTextIntoLines(formattedText);
    const renderedText: JSX.Element[] = [];
    let listItems: JSX.Element[] = [];

    lines.forEach((line: string, index: number) => {
        if (line.trim() === "") {
            return;
        }

        const isListItem: boolean = isLineListItem(line);
        const content: string = getContentFromLine(isListItem, line);
        const segments: JSX.Element[] = processSegments(content, linkBase);

        if (isListItem) {
            listItems = handleListItem(listItems, segments, name, index);
        } else {
            handleNonListItem(renderedText, listItems, segments, index);
        }
    });

    if (listItems.length > 0) {
        renderedText.push(<ul key={`ul-${renderedText.length}`}>{listItems}</ul>);
    }

    return <div>{renderedText}</div>;
}

function unescapeText(text: string): string {
    // Define a regular expression to match escaped characters like \'
    const escapeRegex: RegExp = /\\('|"|\\|\/|b|f|n|r|t|u[0-9A-Fa-f]{4})/g;

    // Replace the escaped characters with their unescaped equivalents
    const unescapedText = text.replace(escapeRegex, (_, escapeSequence) => {
        switch (escapeSequence) {
            case "\\'":
                return "'";
            case '\\"':
                return '"';
            case "\\\\":
                return "\\";
            case "\\/":
                return "/";
            case "\\b":
                return "\b";
            case "\\f":
                return "\f";
            case "\\n":
                return "\n";
            case "\\r":
                return "\r";
            case "\\t":
                return "\t";
            default:
                // Handle Unicode escape sequences (like \uXXXX)
                if (escapeSequence.startsWith("\\u")) {
                    return String.fromCharCode(parseInt(escapeSequence.substr(2), 16));
                }
                return escapeSequence;
        }
    });
    return unescapedText;
}

function decodeText(text: string): string {
    return text.replace(/&lt;/g, "<").replace(/&gt;/g, ">").replace(/&amp;/g, "&");
}

function splitTextIntoLines(text: string): string[] {
    return text.split("\n");
}

function isLineListItem(line: string): boolean {
    return line.trim().startsWith("- ");
}

function getContentFromLine(isListItem: boolean, line: string): string {
    return isListItem ? line.replace(/^\s*-\s*/, "") : line;
}

// TODO: Some generated XML content need to be converted
// The following entities will be rendered differently than what is currently shown:
// - "IAsyncEnumerable1" will be rendered as the string "Enumerable".
// - "FeedResult1" will be rendered as a link labeled "FeedResult".
// - "BETA_TAG_PLACEHOLDER" will be rendered as a BetaPill component.
function processSegments(content: string, linkBase: string): JSX.Element[] {
    const segments: JSX.Element[] = [];
    let currentIndex = 0;

    // replace <see> tags with anchor tags
    const seeTagRegex: RegExp = /<see cref="([^"]*)"[^>]*>/g;
    let match;
    while ((match = seeTagRegex.exec(content)) !== null) {
        const start: number = currentIndex;
        const end: number = match.index;
        if (start < end) {
            segments.push(<span key={`span-${currentIndex}`}>{content.slice(start, end)}</span>);
        }
        let memberName = match[1];
        let memberNamePrefix = memberName[0];
        let cref: string[] = memberName.split(".");
        let objectName = (memberNamePrefix === "T") ? cref[cref.length - 1].replace(/[^a-zA-Z0-9]/g, "") : cref[cref.length - 2].replace(/[^a-zA-Z0-9]/g, "");
        let linkText: string = cref[cref.length - 1].replace(/[^a-zA-Z0-9]/g, "");

        if (linkText === "IAsyncEnumerable1") {
            linkText = "enumerable";
            segments.push(<span key={`span-${currentIndex}`}>{linkText}</span>);
        } else {
            if (linkText === "FeedResult1") {
                linkText = "FeedResult";
            }

            let link;
            if (memberName.includes(":System")){
                link = `https://docs.microsoft.com/en-us/dotnet/api/${memberName.slice(2).toLocaleLowerCase()}`;
            } else if (cref.includes("ObjectModel") || cref.includes("Settings")) {
                link = `${linkBase}/${objectName}`;
            } else {
                link = `#${linkText}`;
            }

            segments.push(
                <HashLink key={`hashlink-${currentIndex}`} to={link}>
                    {linkText}
                </HashLink>
            );
        }
        currentIndex = seeTagRegex.lastIndex;
    }

    // Handle the case where a dash follows a <see> tag
    if (currentIndex < content.length && content[currentIndex] === "-") {
        currentIndex++; // Skip the dash
    }

    // Handle the case where the content contains an <a> tag
    const anchorTagRegex: RegExp = /<a href="([^"]*)">(.*?)<\/a>/g;
    let anchorMatch;
    while ((anchorMatch = anchorTagRegex.exec(content)) !== null) {
        const start: number = currentIndex;
        const end: number = anchorMatch.index;
        if (start < end) {
            segments.push(<span key={`span-${currentIndex}`}>{content.slice(start, end)}</span>);
        }

        const link: string = anchorMatch[1];
        const linkText: string = anchorMatch[2];
        segments.push(
            <a key={`a-${currentIndex}`} href={link}>
                {linkText}
            </a>
        );
        currentIndex = anchorTagRegex.lastIndex;
    }

    const betaTagRegex: RegExp = /BETA_TAG_PLACEHOLDER/g;
    let betaMatch;
    while ((betaMatch = betaTagRegex.exec(content)) !== null) {
        const start: number = currentIndex;
        const end: number = betaMatch.index;
        if (start < end) {
            segments.push(<span key={`span-${currentIndex}`}>{content.slice(start, end)}</span>);
        }
        segments.push(<BetaPill key={`pill-${currentIndex}`} />);
        currentIndex = betaTagRegex.lastIndex;
    }

    const remainingText: string = content.slice(currentIndex).replace(/\/>/g, "");

    if (remainingText) {
        segments.push(<span key={`span-${currentIndex}`}>{remainingText}</span>);
    }
    return segments;
}

function handleListItem(listItems: JSX.Element[], segments: JSX.Element[], name: string, index: number): JSX.Element[] {
    let updatedListItems: JSX.Element[] = [...listItems];
    updatedListItems.push(<li key={`li-${name}-${index}`}>{segments}</li>);
    return updatedListItems;
}

function handleNonListItem(renderedText: JSX.Element[], listItems: JSX.Element[], segments: JSX.Element[], index: number): void {
    if (listItems.length > 0) {
        renderedText.push(<ul key={`ul-${renderedText.length}`}>{listItems}</ul>);
    }
    renderedText.push(<div key={`p-${index}`}>{segments}</div>);
}

function replaceBreakTags(text: string): string {
    return text.replace(/<br\/>/g, "\n");
}
