/* eslint-disable */
import { RateLimit } from "./apiReferenceUtils";
import { ApiMethodParameter, ApiMethodData } from "../ApiMethod";
import { ApiObjectProperty, ApiObjectData } from "../ApiObject";

interface MyGParameterInfo extends ApiMethodParameter {
    isSupported: boolean;
    isRequired: boolean;
    isBeta: boolean;
}

interface MyGPropertyInfo extends ApiObjectProperty {
    isSupported: boolean;
    isBeta: boolean;
}

export interface MyGMethodInfo extends ApiMethodData {
    parameters: MyGParameterInfo[];
    isSupported: boolean;
    isBeta: boolean;
    rateLimits: RateLimit[];
}

export interface MyGObjectInfo extends ApiObjectData {
    properties: MyGPropertyInfo[];
    isSupported: boolean;
    hasValues: boolean;
    isBeta: boolean;
    baseType?: string;
}

export interface MyGParserOutput {
    [name: string]: MyGMethodInfo | MyGObjectInfo;
}

const CREDENTIALS_METHOD_PARAMETERS: MyGParameterInfo = {
    name: "credentials",
    description: 'The user\'s Geotab login <see cref="T:Geotab.Checkmate.ObjectModel.Credentials" />.',
    isRequired: true,
    isBeta: false,
    dataType: "Object",
    isSupported: true
};

const TYPENAME_METHOD_PARAMETERS: MyGParameterInfo = {
    name: "typeName",
    description: 'Identifies the type of entity that is being passed to the next parameter. For example, <see cref="T:Geotab.Checkmate.ObjectModel.Device" />.',
    isRequired: true,
    dataType: "String",
    isBeta: false,
    isSupported: true
};

function extractSubstrings(input: string): string {
    const webMethodsMatch: RegExpMatchArray | null = input.match(/WebMethods\.([a-zA-Z]+)/);
    const dataStoreMatch: RegExpMatchArray | null = input.match(/DataStore\.([a-zA-Z]+)/);

    if (webMethodsMatch) {
        return webMethodsMatch[1];
    } else if (dataStoreMatch) {
        return dataStoreMatch[1];
    } else {
        return "";
    }
}

function removeGetsOrSetsDescription(description: string): string {
    let capitalizedResult: string = description;
    const regexGetsOrSets: RegExp = /^(Gets or sets)\s+/i;
    const regexGets: RegExp = /^(Gets)\s+/i;
    let result;
    if (description.startsWith("Gets or sets")) {
        result = description.replace(regexGetsOrSets, ""); // Remove "Gets or sets" from the beginning
    } else if (description.startsWith("Gets")) {
        result = description.replace(regexGets, ""); // Remove "Gets" from the beginning
    }
    if (result) {
        capitalizedResult = result.charAt(0).toUpperCase() + result.slice(1); // Capitalize the first letter and make the rest lowercase
    }
    return capitalizedResult;
}

function isMethod(namespace: string): boolean {
    return namespace.includes("M:CheckmateServer.Web.WebMethods") || namespace.includes("M:Geotab.Checkmate.Database.DataStore");
}

function isObject(namespace: string): boolean {
    return (
        namespace.includes("T:") &&
        !namespace.includes("T:Geotab.StoreForward") &&
        !namespace.includes("T:Geotab.Checkmate.ObjectModel.UnitConversion") &&
        !namespace.includes("T:CheckmateServer.Web.WebMethods")
    );
}

function isObjectProperty(namespace: string): boolean {
    return namespace.includes("P:") || namespace.includes("F:");
}

function isMyGObject(parameterName: string | null): boolean {
    if (parameterName && typeof window !== "undefined") {
        let storageItem = parameterName.charAt(0).toUpperCase() + parameterName.slice(1) + "MYG";
        return JSON.parse(sessionStorage.getItem(storageItem)!)?.hasOwnProperty("properties");
    } else {
        return false;
    }
}

function isMyGId(parameterName: string | null): boolean {
    return parameterName === "Id";
}

function getValueSeeCref(item: Element): string {
    let valueElements = item.getElementsByTagName("value");
    for (let i = 0; i < valueElements.length; i++) {
        let seeElements = valueElements[i].getElementsByTagName("see");
        for (let j = 0; j < seeElements.length; j++) {
            return seeElements[j].attributes.getNamedItem("cref")?.nodeValue || "";
        }
    }
    return "";
}

function getDataTypeFromNamespace(namespace: string): string {
    let namespaceArray: string[] = namespace.split(".");
    return namespaceArray[namespaceArray.length - 1];
}

function parseMethodInfo(methodName: string, item: Element, namespace: string): MyGMethodInfo {
    let method: MyGMethodInfo = {
        description: "",
        parameters: [],
        returns: "",
        example: "",
        isSupported: true,
        isBeta: false,
        rateLimits: []
    };
    let dataTypeArray: string[] = getMethodParameterDataTypeFromNamespace(namespace);
    if (isGenericMethod(namespace)) {
        method.parameters.push(TYPENAME_METHOD_PARAMETERS);
    }

    for (let j = 0; j < item.childNodes.length; j++) {
        if (item.childNodes[j].nodeName === "isSupported") {
            if ((item.childNodes[j] as Element).attributes.hasOwnProperty("beta")) {
                method.isBeta = true;
            }
            if ((item.childNodes[j] as Element).innerHTML === "false" || (item.childNodes[j] as Element).innerHTML === "false.") {
                method.isSupported = false;
            }
            if (!(item.childNodes[j] as Element).hasAttribute("noAuthenticationNeeded")) {
                method.parameters.push(CREDENTIALS_METHOD_PARAMETERS);
            }
        }
        // this is for the method's description
        if (item.childNodes[j].nodeName === "summary") {
            if (item.childNodes[j].hasChildNodes()) {
                // Iterate through each child node of the summary element to extract relevant information such as paragraphs (para), lists(list), and references(see).
                let summaryChildren = item.childNodes[j].childNodes;
                let summaryText = "";
                for (let k = 0; k < summaryChildren.length; k++) {
                    if (summaryChildren[k].nodeName === "para") {
                        for (let l = 0; l < summaryChildren[k].childNodes.length; l++) {
                            if (summaryChildren[k].childNodes[l].nodeName === "#text") {
                                summaryText += summaryChildren[k].childNodes[l].nodeValue?.replace(/\s+/g, " ");
                            }
                            if (summaryChildren[k].childNodes[l].nodeName === "see") {
                                summaryText += (summaryChildren[k].childNodes[l] as Element).outerHTML;
                            }
                            if (summaryChildren[k].childNodes[l].nodeName === "list") {
                                let listItems = summaryChildren[k].childNodes[l];
                                for (let m = 0; m < listItems.childNodes.length; m++) {
                                    summaryText += `\n- ${(listItems.childNodes[m].childNodes[0] as Element).innerHTML}`;
                                }
                            }
                        }

                        if ((summaryChildren[k] as Element).attributes.hasOwnProperty("beta")) {
                            summaryText += "BETA_TAG_PLACEHOLDER";
                        }
                        if (k !== summaryChildren.length - 1) {
                            summaryText += "\n";
                        }
                    } else {
                        if (summaryChildren[k].nodeName === "#text") {
                            summaryText += summaryChildren[k].nodeValue?.replace(/\s+/g, " ");
                        }
                        if (summaryChildren[k].nodeName === "see") {
                            summaryText += (summaryChildren[k] as Element).outerHTML;
                        }
                    }
                }
                method.description = summaryText.trimStart();
            }
        }
        if (item.childNodes[j].nodeName === "param") {
            if (
                (item.childNodes[j] as Element).attributes.hasOwnProperty("jsHide") ||
                (item.childNodes[j] as Element).getAttribute("name") === "context" ||
                (item.childNodes[j] as Element).getAttribute("name") === "cancellationToken" ||
                (item.childNodes[j] as Element).getAttribute("name") === "dataStore"
            ) {
                dataTypeArray.shift()!;
            } else {
                let descriptionText = "";
                for (let k = 0; k < item.childNodes[j].childNodes.length; k++) {
                    if (item.childNodes[j].childNodes[k].nodeName === "#text") {
                        descriptionText += item.childNodes[j].childNodes[k].nodeValue?.replace(/\s+/g, " ");
                    }
                    if (item.childNodes[j].childNodes[k].nodeName === "see") {
                        descriptionText += (item.childNodes[j].childNodes[k] as Element).outerHTML;
                    }
                    if (item.childNodes[j].childNodes[k].nodeName === "list") {
                        let listItems = item.childNodes[j].childNodes[k];
                        for (let m = 0; m < listItems.childNodes.length; m++) {
                            descriptionText += `\n- ${(listItems.childNodes[m].childNodes[0] as Element).innerHTML}`;
                        }
                    }
                }
                let parameterName = (item.childNodes[j] as Element).attributes.getNamedItem("name")?.nodeValue || null;
                let dataType =
                    dataTypeArray.length > 0
                        ? dataTypeArray.shift()!
                        : isMyGObject(parameterName)
                          ? "Object"
                          : isMyGId(parameterName)
                            ? "String"
                            : getValueSeeCref(item) && isMyGObject(getDataTypeFromNamespace(getValueSeeCref(item)))
                              ? "Object"
                              : getValueSeeCref(item)
                                ? getDataTypeFromNamespace(getValueSeeCref(item)) // get namespace's datatype
                                : "";

                let paramDict: MyGParameterInfo = {
                    name: parameterName || "",
                    description: removeGetsOrSetsDescription(descriptionText.trim()),
                    isRequired: (item.childNodes[j] as Element).attributes.hasOwnProperty("required"),
                    isBeta: (item.childNodes[j] as Element).attributes.hasOwnProperty("beta"),
                    dataType: dataType,
                    isSupported: true
                };
                method.parameters.push(paramDict);
            }
        }
        if (item.childNodes[j].nodeName === "returns") {
            let returnText = "";
            for (let k = 0; k < item.childNodes[j].childNodes.length; k++) {
                if (item.childNodes[j].childNodes[k].nodeName === "#text") {
                    returnText += (item.childNodes[j].childNodes[k] as Element).nodeValue?.replace(/\s+/g, " ");
                }
                if (item.childNodes[j].childNodes[k].nodeName === "see") {
                    returnText += (item.childNodes[j].childNodes[k] as Element).outerHTML;
                }
            }
            method.returns = returnText.trimStart();
        }
        if (item.childNodes[j].nodeName === "example") {
            let codeText = "";
            for (let k = 0; k < item.childNodes[j].childNodes.length; k++) {
                if (item.childNodes[j].childNodes[k].nodeName === "code") {
                    codeText += (item.childNodes[j].childNodes[k] as Element).innerHTML;
                }
            }
            method.example = codeText.trimStart();
        }
        if (item.childNodes[j].nodeName === "rateLimit") {
            let limit = (item.childNodes[j] as Element).getAttribute("limit") || "";
            let period = (item.childNodes[j] as Element).getAttribute("period") || "";
            let description = (item.childNodes[j] as Element).innerHTML;
            let hasActiveAttribute = (item.childNodes[j] as Element).attributes.hasOwnProperty("active");
            let isActive = (item.childNodes[j] as Element).getAttribute("active") === "true";

            let rateLimit: RateLimit = {
                name: methodName,
                limit: limit,
                period: period,
                description: description,
                status: hasActiveAttribute && isActive ? "Active" : "Coming soon"
            };
            method.rateLimits.push(rateLimit);
        }
    }
    return method;
}

function parseObjectInfo(item: Element): MyGObjectInfo {
    let object: MyGObjectInfo = {
        description: "",
        properties: [],
        isBeta: false,
        isSupported: true,
        hasValues: false
    };

    let baseTypeNamespace = item.attributes.getNamedItem("baseType")?.nodeValue || null;
    if (baseTypeNamespace) {
        object.baseType = getDataTypeFromNamespace(baseTypeNamespace);
    }
    for (let j = 0; j < item.childNodes.length; j++) {
        if (item.childNodes[j].nodeName === "isSupported" && (item.childNodes[j] as Element).attributes.hasOwnProperty("beta")) {
            object.isBeta = true;
        }
        if (item.childNodes[j].nodeName === "summary") {
            if (item.childNodes[j].hasChildNodes()) {
                let summaryChildren = item.childNodes[j].childNodes;
                let summaryText: string = "";
                for (let k = 0; k < summaryChildren.length; k++) {
                    if (summaryChildren[k].nodeName === "text" || summaryChildren[k].nodeName === "#text") {
                        summaryText += summaryChildren[k].nodeValue?.replace(/\s+/g, " ");
                    }
                    if (summaryChildren[k].nodeName === "see" || summaryChildren[k].nodeName === "a") {
                        summaryText += (summaryChildren[k] as Element).outerHTML;
                    }
                    if (summaryChildren[k].nodeName === "para") {
                        for (let l = 0; l < summaryChildren[k].childNodes.length; l++) {
                            if (summaryChildren[k].childNodes[l].nodeName === "#text") {
                                summaryText += summaryChildren[k].childNodes[l].nodeValue?.replace(/\s+/g, " ");
                            }
                            if (summaryChildren[k].childNodes[l].nodeName === "see") {
                                summaryText += (summaryChildren[k].childNodes[l] as Element).outerHTML;
                            }
                        }

                        if (k !== summaryChildren.length - 1) {
                            summaryText += "\n";
                        }
                    }
                    if (summaryChildren[k].nodeName === "list") {
                        let listItems = summaryChildren[k].childNodes;
                        for (let l = 0; l < listItems.length; l++) {
                            for (let m = 0; m < listItems[l].childNodes.length; m++) {
                                if (listItems[l].childNodes[m].childNodes[0].nodeName === "see") {
                                    summaryText += `\n- ${(listItems[l].childNodes[m].childNodes[0] as Element).outerHTML}`;
                                } else {
                                    summaryText += `\n- ${listItems[l].childNodes[m].childNodes[0].nodeValue?.replace(/\s+/g, " ") ?? ""}`;
                                }
                            }
                        }
                    }
                }
                object.description = removeGetsOrSetsDescription(summaryText.trimStart());
            }
        }
        if (item.childNodes[j].nodeName === "rateLimit") {
            if (!object.rateLimits) {
                object.rateLimits = [];
            }
            let name = (item.childNodes[j] as Element).getAttribute("method") || "";
            let limit = (item.childNodes[j] as Element).getAttribute("limit") || "";
            let period = (item.childNodes[j] as Element).getAttribute("period") || "";
            let description = (item.childNodes[j] as Element).innerHTML;
            let hasActiveAttribute = (item.childNodes[j] as Element).attributes.hasOwnProperty("active");
            let isActive = (item.childNodes[j] as Element).getAttribute("active") === "true";

            let rateLimit: RateLimit = {
                name: name,
                limit: limit,
                period: period,
                description: description,
                status: hasActiveAttribute && isActive ? "Active" : "Coming soon"
            };
            object.rateLimits.push(rateLimit);
        }

        if (item.childNodes[j].nodeName === "paging") {
            object.pagination = {
                supportedSorts: [],
                resultsLimit: "",
                isBeta: false,
                dateSortProperty: undefined
            };

            object.pagination.supportedSorts = (item.childNodes[j] as Element).getAttribute("sortSupported")?.split(";") ?? [];
            object.pagination.resultsLimit = (item.childNodes[j] as Element).getAttribute("limit") ?? "";
            object.pagination.isBeta = (item.childNodes[j] as Element).attributes.hasOwnProperty("beta");
            // Assigning the optional property if it exists
            if ((item.childNodes[j] as Element).hasAttribute("dateSortProperty")) {
                object.pagination.dateSortProperty = (item.childNodes[j] as Element).getAttribute("dateSortProperty") ?? "";
            }
        }
    }
    return object;
}

function parseObjectPropertiesInfo(item: Element, propertyName: string): MyGPropertyInfo {
    let objectProperty: MyGPropertyInfo = {
        name: propertyName,
        description: "",
        isBeta: false,
        isSupported: true,
        dataType: ""
    };

    let valueNamespace = getValueSeeCref(item);
    let namespaceDataType = getDataTypeFromNamespace(valueNamespace);
    let dataType = valueNamespace && isMyGId(namespaceDataType) ? "String" : namespaceDataType;
    objectProperty.dataType = dataType;
    // NOTE: will check to see if property is my g object after dictionary is built (see transformJSON function)

    let descriptionText = "";
    for (let j = 0; j < item.childNodes.length; j++) {
        if (item.childNodes[j].nodeName === "isSupported") {
            if ((item.childNodes[j] as Element).innerHTML === "false" || (item.childNodes[j] as Element).innerHTML === "false.") {
                objectProperty.isSupported = false;
            }
        }
        if (item.childNodes[j].nodeName === "summary") {
            for (let k = 0; k < item.childNodes[j].childNodes.length; k++) {
                if (item.childNodes[j].childNodes[k].nodeName === "isSupported") {
                    if ((item.childNodes[j].childNodes[k] as Element).innerHTML === "false" || (item.childNodes[j].childNodes[k] as Element).innerHTML === "false.") {
                        objectProperty.isSupported = false;
                    }
                }
                if (item.childNodes[j].childNodes[k].nodeName === "#text") {
                    descriptionText += (item.childNodes[j].childNodes[k] as Element).nodeValue?.replace(/\s+/g, " ");
                }
                if (item.childNodes[j].childNodes[k].nodeName === "see") {
                    descriptionText += (item.childNodes[j].childNodes[k] as Element).outerHTML;
                }
                if (item.childNodes[j].childNodes[k].nodeName === "list") {
                    let propertyListItems = item.childNodes[j].childNodes[k].childNodes;
                    for (let l = 0; l < propertyListItems.length; l++) {
                        for (let m = 0; m < propertyListItems[l].childNodes.length; m++) {
                            if (propertyListItems[l].childNodes[m].childNodes[0].nodeName === "see") {
                                descriptionText += "\n" + "- " + (propertyListItems[l].childNodes[m].childNodes[0] as Element).outerHTML;
                            } else {
                                descriptionText += "\n" + "- " + (propertyListItems[l].childNodes[m].childNodes[0] as Element).nodeValue?.replace(/\s+/g, " ");
                            }
                        }
                    }
                }
                if (item.childNodes[j].childNodes[k].nodeName === "para") {
                    descriptionText += (item.childNodes[j].childNodes[k] as Element).innerHTML;
                }
            }
        }
    }
    objectProperty.description = removeGetsOrSetsDescription(descriptionText.trimStart());
    return objectProperty;
}

function isGenericMethod(namespace: string): boolean {
    let namespaceWithoutParameters = removeEndingSubstring(namespace);
    return namespaceWithoutParameters.endsWith("``1") || namespaceWithoutParameters.endsWith("``2");
}

function removeEndingSubstring(namespace: string): string {
    const regex: RegExp = /\([^()]*\)$/;
    return namespace.replace(regex, "");
}

// Parses a method signature and returns an array of parameter data types, including handling for asynchronous tasks and cancellation tokens.
// Example input: M:CheckmateServer.Web.WebMethods.AuthenticateAsync(Microsoft.AspNetCore.Http.HttpContext,System.String,System.String,System.String,System.String,System.Boolean,System.Threading.CancellationToken@)
// Example output: ['HttpContext', 'String', 'String', 'String', 'String', 'Boolean', 'CancellationToken']
function getMethodParameterDataTypeFromNamespace(namespace: string): string[] {
    let openingParenthesisIndex = namespace.indexOf("(");
    let parametersString = openingParenthesisIndex > -1 ? namespace.substring(openingParenthesisIndex + 1, namespace.length - 1) : "";
    let parameterArray = parametersString
        .split(",")
        .filter((item) => item.trim() !== "``0" && item.trim() !== "")
        .map((item) => {
            let shortDataType = convertLongToShort(item);
            return isMyGObject(shortDataType) ? (shortDataType = "Object") : shortDataType;
        });
    return parameterArray;
}

function convertLongToShort(longType: string): string {
    const regex =
        /(?:Geotab\.|Microsoft\.AspNetCore\.Http\.)?(?:Checkmate\.ObjectModel\.)?(?:[A-Za-z]+\.)*Nullable\{\s*System\.\s*([A-Za-z0-9]+)\s*\}|(?:Geotab\.|Microsoft\.AspNetCore\.Http\.)?(?:Checkmate\.ObjectModel\.)?(?:[A-Za-z]+\.)*([A-Za-z0-9]+)/g;
    const match = regex.exec(longType);
    if (match) {
        if (match[1]) {
            return match[1]; // For Nullable case
        } else if (match[2]) {
            return match[2]; // For regular case
        }
    }
    return longType;
}

function transformJSON(json: { [key: string]: MyGMethodInfo | MyGObjectInfo }) {
    Object.keys(json).forEach((key) => {
        let value = json[key];
        if (value && (value as MyGObjectInfo).properties) {
            for (let i = 0; i < (json[key] as MyGObjectInfo).properties.length; i++) {
                let prop = (json[key] as MyGObjectInfo).properties[i];
                if (json[prop.dataType] as MyGObjectInfo) {
                    (json[key] as MyGObjectInfo).properties[i].dataType = "Object";
                }
            }
            while (value && (value as MyGObjectInfo).baseType !== undefined && (value as MyGObjectInfo).baseType !== null && (value as MyGObjectInfo).baseType !== "") {
                const baseType = (value as MyGObjectInfo).baseType;
                if (baseType) {
                    if (json[key] && json[baseType] && (json[key] as MyGObjectInfo).properties && (json[baseType] as MyGObjectInfo).properties) {
                        const currentProperties = (json[key] as MyGObjectInfo).properties;
                        const baseProperties = (json[baseType] as MyGObjectInfo).properties;
                        const mergedProperties = currentProperties.concat(baseProperties.filter((baseProp) => !currentProperties.some((currentProp) => currentProp.name === baseProp.name)));
                        (json[key] as MyGObjectInfo).properties = mergedProperties;
                    }
                    value = json[baseType];
                }
            }
            if (json[key] && (json[key] as MyGObjectInfo).baseType) {
                delete (json[key] as MyGObjectInfo).baseType;
            }
        }
    });
}

function sortJSON(json: { [key: string]: MyGMethodInfo | MyGObjectInfo }) {
    Object.keys(json).forEach((key) => {
        if (json[key] && (json[key] as MyGMethodInfo).parameters) {
            (json[key] as MyGMethodInfo).parameters = (json[key] as MyGMethodInfo).parameters.sort((a, b) => a.name.localeCompare(b.name));
        } else if (json[key] && (json[key] as MyGObjectInfo).properties) {
            (json[key] as MyGObjectInfo).properties = (json[key] as MyGObjectInfo).properties.sort((a, b) => a.name.localeCompare(b.name));
        }
    });
}

export default function myGParser(xml: XMLDocument, itemType: string): MyGParserOutput {
    let json: { [key: string]: MyGMethodInfo | MyGObjectInfo } = {};
    if (xml.hasChildNodes()) {
        if (xml.childNodes[0].nodeName === "doc") {
            let item: NodeListOf<ChildNode> = xml.childNodes[0].childNodes;
            for (let i = 0; i < item.length; i++) {
                if (item[i].nodeName === "member") {
                    let node = item[i] as Element;
                    let namespace: string = node.attributes.getNamedItem("name")?.nodeValue ?? "";
                    if (itemType === "method" && isMethod(namespace)) {
                        let methodName: string = extractSubstrings(namespace).replace(/Async$/, "");
                        let methodInfo = parseMethodInfo(methodName, node, namespace);
                        if (!json[methodName]) {
                            json[methodName] = methodInfo;
                        }
                    } else if (itemType === "object" && isObject(namespace)) {
                        let namespaceArray: string[] = namespace.split(".");
                        // Include digits 0-9 to accommodate Go4v3 and GO4-9, but they should be excluded for geFeed1 and feedResult1.
                        let objectName: string = namespaceArray[namespaceArray.length - 1].replace(/[^a-zA-Z0-9]/g, "");
                        objectName = objectName === "FeedResult1" ? "FeedResult" : objectName;
                        let objectInfo = parseObjectInfo(node);
                        // SDK team confirmed not to include DataStore
                        if (!json[objectName] && objectName !== "DataStore" && objectInfo.isSupported) {
                            json[objectName] = objectInfo;
                        }
                    } else if (itemType === "object" && isObjectProperty(namespace)) {
                        let namespaceArray: string[] = namespace.split(".");
                        // Include digits 0-9 to accommodate Go4v3 and GO4-9, but they should be excluded for geFeed1 and feedResult1.
                        let objectName: string = namespaceArray[namespaceArray.length - 2].replace(/[^a-zA-Z0-9]/g, "");
                        objectName = objectName === "FeedResult1" ? "FeedResult" : objectName;
                        let objectPropertyName: string = namespaceArray[namespaceArray.length - 1].replace(/[^a-zA-Z0-9]/g, "");
                        let objectPropertyInfo = parseObjectPropertiesInfo(node, objectPropertyName);
                        if ((json[objectName] as MyGObjectInfo) && objectPropertyInfo.isSupported) {
                            (json[objectName] as MyGObjectInfo).properties.push(objectPropertyInfo);
                            (json[objectName] as MyGObjectInfo).hasValues = namespace.startsWith("F:");
                        }
                    }
                }
            }
            transformJSON(json);
            sortJSON(json);
        }
    }

    return json;
}
