import { Pill } from "@dev/zenith";
import { IconBeta } from "./IconBeta";
import "./betaPill.scss";

export default function BetaPill(): JSX.Element {
    return (
        <Pill type={"info"} className="beta-pill">
            <div className="beta-pill-content">
                <IconBeta className="beta-pill-icon" />
                <span className="beta-pill-text">Beta</span>
            </div>
        </Pill>
    );
}
