/*eslint-disable*/
// TODO: This file is a placeholder for the XML parsing logic. Currently, parsing is in myGParser.ts. and myAParser.ts.
// Description:
// Retrieve the XML files and parses it into a JSON object
// XML is being retrieved from the following URLs:
//  - https://myadmin.geotab.com/sdk-xml/MyAdminAPI.xml
//  - https://alpha.geotab.com/sdk.xml

import { RateLimit } from "./apiReferenceUtils";
import { ReferenceEntry } from "../ApiReference";
import { AllRateLimits } from "../../../pages/myGeotab/guides/rateLimits";

// MYG XML EXAMPLE
// <doc>
// <member name="MEMBER NAME" baseType=""">
//      <inheritdoc/>
//      <isSupported noAuthenticationNeeded="true" beta="beta">...</isSupported>
//      <summary>
//          <isSupported>...</isSupported>
//          <para>
//              <list>
//                  <item>
//                      <description> Distance: Meters (m) </description>
//                  </item>
//                  ...
//              </list>
//              <a href="...">...</a>
//              <see cref="..."/>
//              <see cref="..."></see>
//              ...
//          </para>
//          <a href="...">...</a>
//          <see cref="..."/>
//          <see cref="..."></see>
//          ...
//      </summary>
//      <typeparam name="T">...</typeparam>
//      <param name="PARAM NAME" required="required">...</param>
//      <returns>...</returns>
//      <example>...</example>
//      <rateLimit method="..." limit="100" period="1m" active="false">...</rateLimit>
//      <value>
//          <see cref="..."/>
//      </value>
// </member>
// </doc>

// MEMBER_NAME = <?>NAMESPACE.SUB_NAMESPACE...SUB_NAMESPACE|MODULE.NAME
// ?
// T: denotes an object
// M: denotes a method
// P: denotes an object property
// F: denotes an object value

// GETTING VALUES OF XML:
// EXAMPLE: <rateLimit limit="10" period="1m">Allows up to 10 requests per minute.</rateLimit>

// get the name of the element -> Element.nodeName.
// ex. <member name="M: ..."> -> nodeName = "member"
// ex. <rateLimit limit="10" period="1m">Allows up to 10 requests per minute.</rateLimit> -> nodeName = "rateLimit"

// determine if attribute exists -> Element.attributes.hasOwnProperty("ATTRIBUTE_NAME")
// ex. <member name="M: ..."> -> Element.attributes.hasOwnProperty("name") = true
// ex. <rateLimit limit="10" period="1m">Allows up to 10 requests per minute.</rateLimit> -> Element.attributes.hasOwnProperty("limit") = true

// get value of ATTRIBUTE_NAME -> Element.getAttribute("ATTRIBUTE_NAME").
// ex. <member name="M: ..."> -> Element.getAttribute("name") = "M: ..."
// ex. <rateLimit limit="10" period="1m">Allows up to 10 requests per minute.</rateLimit> -> Element.getAttribute("limit") = "10"

// get content within the tag -> Element.innerHTML
// ex. <member name="M: ...">CONTENT</member> -> Element.innerHTML = "CONTENT"
// ex. <rateLimit limit="10" period="1m">Allows up to 10 requests per minute.</rateLimit> -> Element.innerHTML = "Allows up to 10 requests per minute."

// These interfaces will be imported from a different file.
// high level overview of how the fields will be assigned
interface ApiMethodParameter {
    // NAME
    // To get:
    // <member name="M: ..."><param name="NAME">...</param></member>
    name: string;
    // DESCRIPTION
    // To get:
    // <member name="M: ..."><param name="...">DESCRIPTION</param></member> (NOTE: will need to modify - remove "gets or sets" or "gets" or "sets" from description)
    description: string;
    // DATA TYPE
    // To get:
    // if method signature has arguments then retrieved from the method signature
    // - check to see if it is a Geotab native object,
    // - if yes, then "Object",
    // - else if it is an Geotab native Id, then "String",
    // - check check treat NAME like a method signature and get the data type from NAME
    // - else ""
    // NOTE: might need to get this after parsing the entire XML
    dataType: string;
    // DATA TYPE TOOLTIP
    // To get:
    // is "" by default
    // if dataType is a special case, display the tooltip
    // special cases: long, Long, Int64, DateTime, Nullable{DateTime}, date, TimeSpan, timespan, byteArray
    // NOTE: might need to get this after parsing the entire XML
    dataTypeTooltip: string;
    // IS SUPPORTED
    // To get:
    // <member name="M: ..."><isSupported>IS_SUPPORTED</isSupported></member>
    // NOTE: if "isSupported" does not exists, then true) (NOTE: may not need, only push if isSupported is true
    isSupported?: boolean;
    // IS REQUIRED
    // To get:
    // <member name="M: ..."><param name="..." required="required">...</param></member>
    // if "required" exists, then true, else false
    isRequired: boolean;
    // IS BETA
    // To get:
    // <member name="M: ..."><isSupported beta="beta">...</isSupported></member> if "beta" exists, then true, else false (NOTE: myg only)
    // if "beta" exists, then true, else false
    // NOTE: myg only
    isBeta?: boolean;
}

export interface ApiRateLimit {
    name: string;
    limit: string;
    period: string;
    description: string;
    status: string;
}
interface ApiMethod {
    description: string;
    parameters: ApiMethodParameter[];
    returns: string;
    example: string;
    remarks?: string; // TODO: remarks is not being displayed. this is only in mya
    isSupported?: boolean; // myg and mya
    isBeta?: boolean; // myg
    rateLimits?: ApiRateLimit[];
}

interface ApiObjectProperty {
    name: string;
    description: string;
    dataType: string;
    dataTypeTooltip?: string;
    isSupported?: boolean; // myg and mya
    isBeta?: boolean; // myg
}

interface ApiObject {
    description: string;
    properties: ApiObjectProperty[];
    isSupported?: boolean; // myg and mya
    hasValues?: boolean; // myg and mya
    isBeta?: boolean; // myg
    baseType?: string; // myg
    rateLimits?: ApiRateLimit[];
}

type Product = "myAdmin" | "myGeotab" | "drive";
type ApiInterfaces = "methods" | "objects" | "objectProperties";

interface JsonData {
    myAdmin: ApiData;
    myGeotab: ApiData;
    drive?: ApiData;
}

interface ApiData {
    objects: { [name: string]: ApiObject };
    methods: { [name: string]: ApiMethod };
}

const CREDENTIALS_METHOD_PARAMETERS: ApiMethodParameter = {
    name: "credentials",
    description: 'The user\'s Geotab login <see cref="T:Geotab.Checkmate.ObjectModel.Credentials" />.',
    dataType: "Object",
    dataTypeTooltip: "",
    isRequired: true,
    isBeta: false
};

const TYPENAME_METHOD_PARAMETERS: ApiMethodParameter = {
    name: "typeName",
    description: 'Identifies the type of entity that is being passed to the next parameter. For example, <see cref="T:Geotab.Checkmate.ObjectModel.Device" />.',
    dataType: "String",
    dataTypeTooltip: "",
    isRequired: true,
    isBeta: false
};

// When XML parsing, nodes will fall into one of the following categories: method, object, or objectProperty.
// The node's category can be inferred from its memberName (e.g., <member name="MEMBER_NAME"> ... </member>).
// MEMBER_NAME follow this pattern: <PREFIX>: <MEMBER_NAME.<SUB_NAMESPACE>...<SUB_NAMESPACE|MODULE>.<NAME>
// For example, "M:Geotab.Checkmate.Database.DataStore.Get":
// - Prefix 'M' denotes a method.
// - Prefix 'T' denotes an object.
// - Prefix 'P' denotes an object property.
// - Prefix 'F' denotes an object value.
function isMethodTag(memberName: string): boolean {
    return memberName.startsWith("M:");
}

function isObjectTag(memberName: string): boolean {
    return memberName.startsWith("T:");
}

function isObjectPropertyTag(memberName: string): boolean {
    return memberName.startsWith("P:") || memberName.startsWith("F:");
}

// These functions parse the XML node and return the corresponding data.
function parseMethodNode(item: Element): ApiMethod {
    let method: ApiMethod = {
        description: "",
        parameters: [],
        returns: "",
        example: "",
        remarks: "",
        isSupported: true,
        isBeta: false,
        rateLimits: []
    };
    for (let i = 0; i < item.children.length; i++) {
        let child = item.children[i];
        switch (child.nodeName) {
            case "summary":
                method.description += "";
                break;
            case "param":
                method.parameters.push({} as ApiMethodParameter);
                break;
            case "returns":
                method.returns = "";
                break;
            case "example":
                method.example = "";
                break;
            case "remarks":
                method.remarks = "";
                break;
            case "isSupported":
                method.isSupported = true;
                break;
            case "rateLimit":
                method.rateLimits?.push({} as ApiRateLimit);
                break;
            default:
                console.error("Unknown node: " + child.nodeName);
                break;
        }
    }
    return method;
}

function parseObjectNode(item: Element): ApiObject {
    let object: ApiObject = {
        description: "",
        properties: [],
        isSupported: true,
        hasValues: true,
        isBeta: false,
        baseType: "",
        rateLimits: []
    };
    for (let i = 0; i < item.children.length; i++) {
        let child = item.children[i];
        switch (child.nodeName) {
            case "summary":
                object.description += "";
                break;
            case "param":
                object.properties.push({} as ApiObjectProperty);
                break;
            case "isSupported":
                object.isSupported = true;
                break;
            case "rateLimit":
                object.rateLimits?.push({} as ApiRateLimit);
                break;
            default:
                console.error("Unknown node: " + child.nodeName);
                break;
        }
    }
    return object;
}

function parseObjectPropertyNode(item: Element): ApiObjectProperty {
    let objectProperty: ApiObjectProperty = {
        name: "",
        description: "",
        dataType: "",
        dataTypeTooltip: "",
        isSupported: true,
        isBeta: false
    };
    for (let i = 0; i < item.children.length; i++) {
        let child = item.children[i];
        switch (child.nodeName) {
            case "summary":
                objectProperty.description += "";
                break;
            case "value":
                objectProperty.dataType += getCrossReference(child);
                break;
            case "isSupported":
                objectProperty.isSupported = true;
                break;
            default:
                console.error("Unknown node: " + child.nodeName);
                break;
        }
    }
    return objectProperty;
}

// Extracts parameters' data types from a methodSignature
// Example input: Authenticate(System.String,System.String)
// Example output: ['String', 'String']
function getParameterDataTypesFromMethodSignature(methodSignature: string): string[] {
    let parameterDataTypes: string[] = [];
    return parameterDataTypes;
}

function removeGetsOrSetsDescription(description: string): string {
    return "";
}

function getCrossReference(item: Element): string {
    return "";
}

function getNameFromMemberName(memberName: string): string {
    let memberNameArray: string[] = memberName.split(".") || [];
    return memberNameArray[memberNameArray.length - 1];
}

export function parseXML(xml: XMLDocument): JsonData {
    let jsonResult: JsonData = {
        myAdmin: { objects: {}, methods: {} },
        myGeotab: { objects: {}, methods: {} }
    };

    const childrenOfXMLElement = xml.children;
    for (let i = 0; i < childrenOfXMLElement.length && i < 3; i++) {
        const memberElement = childrenOfXMLElement[i];
        let memberName = memberElement.getAttribute("name") || "ERROR: NO NAME FOUND";
        if (isMethodTag(memberName)) {
        } else if (isObjectTag(memberName)) {
        } else if (isObjectPropertyTag(memberName)) {
        }
    }
    traverseJSON(jsonResult);
    sortJSON(jsonResult);
    buildApiSearchIndex(jsonResult);
    return jsonResult;
}

// After parsing the XML, the JSON is transformed and sorted.
// this is done after because we need the entire JSON to be parsed before we can traverse/sort it.
function traverseJSON(json: any): void {}

// Sorts the JSON's object property names and method parameter names.
function sortJSON(json: any): void {}

// Builds the search index for the API reference.
function buildApiSearchIndex(json: JsonData): void {}

// Determine whether the given name corresponds to a native MyGeotab object.
function isMyGeotabApiObject(name: string): boolean {
    return true;
}

// Determine whether the given name corresponds to a native MyAdmin object.
function isMyAdminApiObject(name: string): boolean {
    return true;
}

// This function determine whether the given name is a Geotab native Id.
function isKnownId(name: string | null): boolean {
    return true;
}

export function pullRateLimitInfo(methodInfo: ReferenceEntry[], objectInfo: ReferenceEntry[]): AllRateLimits {
    let standardRateLimits: { [key: string]: RateLimit[] } = {};
    let nonStandardRateLimits: { [key: string]: RateLimit[] } = {};
    methodInfo.forEach((method) => {
        if (method.details.rateLimits) {
            nonStandardRateLimits[method.name] = method.details.rateLimits;
        }
    });
    objectInfo.forEach((obj) => {
        if (obj.details.rateLimits) {
            standardRateLimits[obj.name] = obj.details.rateLimits;
        }
    });

    return { standardRateLimits: standardRateLimits, nonStandardRateLimits: nonStandardRateLimits };
}
